import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'

import * as s from '../pages/news.module.scss'

const TenderingProcess: React.FC = () => {
  return (
    <>
      <SEO
        title="How long does the tendering process take for most projects?"
        description="To understand how much time owners usually give to suppliers during the bidding process, let's take apart the procurement data"
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          How long does the tendering process take?
        </Typography>
        <Typography variant="body1">
          Do you want to know what a tender is, where to look for participants
          for a deal, and how the bidding market works? In this article, we will
          tell you in detail about electronic trading, and also share with you
          some secrets of searching and participating in them.
        </Typography>
        <Typography variant="h2" color="blue">
          How long does the tendering process take?
        </Typography>
        <Typography variant="body1">
          So what is tendering? A tender is a generalized concept of a large
          competitive purchase. A tender is a method of purchasing goods or
          services based on the principle of open competition.
        </Typography>
        <Typography variant="body1">
          The tender process undoubtedly has a lot of advantages. Before,
          conducting a tender correctly, commercial property owners and their
          team members must spend a lot of time. Each of the participants must
          understand what is a tendering process and how to work effectively in
          the market.
        </Typography>
        <Typography variant="body1">
          Having figured out what is tendering process, you should understand
          how to properly plan your time to get a decision in your favor.
          Indeed, with slow work to participate in the tender, the
          attractiveness of the financial return from the project decreases.
          That is why all bidders need to set clear deadlines.
        </Typography>
        <Typography variant="body1">
          So how long does tendered for delivery take?
        </Typography>
        <Typography variant="h2" color="blue">
          Tendered to delivery service provider: how long does it take, examples
          of tender stages
        </Typography>
        <Typography variant="body1">
          As an example, consider the time owners give suppliers during the
          tender process. The analysis will be carried out on the example of
          procurement at three different stages:
        </Typography>
        <ol className={`${s.textLists} ${s.decimal}`}>
          <li>
            Invitation to participate in the first tender. During this period,
            suppliers are allowed to apply for the first round of tenders. This
            option is provided by the owner. It measures the time from when the
            first bidder is invited to tender software until the first bid is
            returned through the platform.
          </li>
          <li>
            First bid - last bid. All applications are analyzed to identify
            whether there are any questions or clarifications on the project.
            This period also includes the time when the first notice and the
            last corrected notice were received.
          </li>
          <li>
            Award. This is the final stage when, after the last tenders, the
            tender is awarded to the supplier.
          </li>
        </ol>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/tendering-process/image-1.jpg"
            alt="Tendered to delivery service provider: how long does it take, examples of tender stages"
            title="Tendered to delivery service provider: how long does it take, examples of tender stages"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          This time includes all the final negotiations on the project, the
          approval procedure, and the signing of the necessary documents for the
          official award of the tender to the supplier.
        </Typography>
        <Typography variant="h2" color="blue">
          Calculation of tender time
        </Typography>
        <List className="pl-4">
          <li>
            Invitation to participate in the first tender: this takes an average
            of 7-8 days. During this period, participants are invited and
            confirmed to participate in the tender.
          </li>
          <li>
            First application - last application: 4 days required. During this
            period, all applications for participation come and are processed.
          </li>
          <li>
            Award. It takes about a week for the owners to make a decision.
            Usually already on the eighth day tendering for work is awarded.
          </li>
        </List>
        <Typography variant="h2" color="blue">
          Are these deadlines mandatory?
        </Typography>
        <Typography variant="body1">
          It is important to understand that the figures considered are
          certainly not final and may vary depending on the size and complexity
          of the project. Usually, these indicators vary greatly in one
          direction or another.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/tendering-process/image-2.jpg"
            alt="Are these deadlines mandatory"
            title="Are these deadlines mandatory"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          For small and simple projects up to $100,000, the time is reduced to 5
          days for an invitation, all proposals arrive in 3 days, and the award
          occurs within 5 days. But for larger projects, such as tendering for
          building work worth more than $1 million, the tender process takes at
          least 16 days.
        </Typography>
        <Typography variant="h2" color="blue">
          What is the most popular day for tenders?
        </Typography>
        <Typography variant="body1">
          If you, after reading the questions, decided that it was Monday, then
          you did not guess a little. According to statistics, Friday is the
          most popular day for vendors to ask for tender returns. While the
          reason is not clear, either managers align tenders on weekends, or
          managers protect themselves from late submission of information by
          suppliers and give them additional days to ensure that all
          applications are submitted by Monday.
        </Typography>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          It is a properly conducted tendering process that is the key to the
          door to a reliable relationship between the owner and suppliers. If
          each of the participants understands what responsibilities, deadlines,
          and implementation plan are, then the project is very likely to start
          and go perfectly.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default TenderingProcess
